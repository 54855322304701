import Vue from "vue";

/*TITLE*/
document.title = "Nou Vallestany Banyoles | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Nou Vallestany Banyoles";
Vue.prototype.$subtitle = "Vive cerca de l'Estany de Banyoles";

/*INTRO*/
Vue.prototype.$promoter = "Nou Vallestany Banyoles";
Vue.prototype.$introSubtitle = "Vive cerca de l'Estany de Banyoles";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-bremen-natural--20230118090158.jpg";
Vue.prototype.$image_kitchen = "porcelanosa-banyoles-cocina-op05--20230118090117.jpg";
Vue.prototype.$image_bath1 = "banop-opc-2-bottega-acero-bottega-white-bottega-acerospiga-bottega-acero--20230118090152.jpg";
Vue.prototype.$image_bath2 = "banosec-opc.-2.-bremen-natural-china-blanco-deco-bremen-roble-natural--20230118090108.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-bremen-natural--20230118090158.jpg",
  },
  {
    src: "salon-bottega-acero--20230118090106.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "porcelanosa-banyoles-cocina-op05--20230118090117.jpg",
  },
  {
    src: "porcelanosa-banyoles-cocina-op04--20230118090125.jpg",
  },
  {
    src: "porcelanosa-banyoles-cocina-op03--20230118090137.jpg",
  },
  {
    src: "cocina-bottega-acero--20230118090144.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banop-opc-2-bottega-acero-bottega-white-bottega-acerospiga-bottega-acero--20230118090152.jpg",
  },
  {
    src: "banop-opc-1.-bremen-natural-china-blanco-deco-bremen-roble-natural--20230118090102.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banosec-opc.-2.-bremen-natural-china-blanco-deco-bremen-roble-natural--20230118090108.jpg",
  },
  {
    src: "banosec-bottega-white-bottega-acero-spiga-bottega-acero--20230118090114.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/TQ_BANYOLES/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://player.vimeo.com/video/760328222";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=carrer%20llibertat%20169%20176%20banyoles%20girona%2017820",
    text: "Carrer Llibertat 169-176 Banyoles, Girona, 17820",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=banyoles%20girona%2017820",
    text: "Banyoles, Girona, 17820",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:622431945",
    text: "622431945",
  },
];
